import React, { lazy } from "react";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Project = lazy(() => import("./pages/Project"));
const ViewProject = lazy(() => import("./pages/ViewProject"));
const CreateProject = lazy(() => import("./pages/CreateProject"));
const Setting = lazy(() => import("./pages/Setting"));
const Account = lazy(() => import("./pages/Account"));
const VariationDetail = lazy(() => import("./pages/VariationDetail"));
const AddVariation = lazy(() => import("./pages/AddVariation"));
const ViewProjectVariation = lazy(() => import("./pages/ViewProjectVariation"));


const routes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: true
  },
  {
    path: "/",
    component: <Login />,
    exact: true
  },
  {
    path: "/projects",
    component: <Project />,
    exact: true
  },
  {
    path: "/view-project",
    component: <ViewProject />,
    exact: true
  },
  {
    path: "/create-project",
    component: <CreateProject />,
    exact: true
  },
  {
    path: "/setting",
    component: <Setting />,
    exact: true
  },
  {
    path: "/account",
    component: <Account />,
    exact: true
  },
  {
    path: "/variation-detail",
    component: <VariationDetail />,
    exact: true
  },
  {
    path: "/add-variation",
    component: <AddVariation />,
    exact: true
  },
  {
    path: "/view-project-variation",
    component: <ViewProjectVariation />,
    exact: true
  },
];

export default routes;
